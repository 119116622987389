<template>
  <div class="login-box">
    <div class="login-tis" v-if="showTis">
      <img v-if="tistype" src="/assets/imgs/yes.png"  class="tis-img" />
      {{ tis }}
    </div>
    <div>
      <img src="../assets/imgs/loginBg-lingshi.png" alt="" class="img" />
    </div>
    <div class="input-box">
      <div class="inp-top">
        <span @click="forgotClick">Sign in</span>
        <span @click="SignUPClick">Sign up</span>
      </div>
      <Login
        @click-forgot="loginClick"
        @click-sign="SignUPClick"
        @clickTiss="upTis"
      ></Login>
      <div class="inp-bot">
        <span @click="goIndex">< Back to Store</span>
      </div>
    </div>
  </div>
</template>

<script setup>
const route = useRoute();

//展示类型
const loginClick = () => {
  if (route.query.redirect) {
    navigateTo("/forgetPasswd?redirect=" + route.query.redirect);
  } else {
    navigateTo("/forgetPasswd");
  }
};
const goIndex = (val) => {
  navigateTo("/");
}
const forgotClick = (val) => {
  if (route.query.redirect) {
    navigateTo("/login?redirect=" + route.query.redirect);
  } else {
    navigateTo("/login");
  }
};
const SignUPClick = (val) => {
  if (route.query.redirect) {
    navigateTo("/signup?redirect=" + route.query.redirect);
  } else {
    navigateTo("/signup");
  }
};

//提示
const tis = ref("");
const tistype = ref("");

const showTis = ref(false);
const upTis = (val) => {
  console.log(val, " pefpdpsdppsdfp  ");
  if (typeof val == "string") {
    tis.value = val;
    showTis.value = true;
    // setTimeout(() => {
    //   showTis.value = false;
    // }, 2000);
  } else {
    tistype.value = val.type;
    tis.value = val.msg;
    showTis.value = true;
    // setTimeout(() => {
    //   showTis.value = false;
    //   tistype.value = "";
    // }, 2000);
  }
};
</script>

<style lang="less" scoped>
.login-box {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .tisErr {
    color: #ff0000 !important;
  }

  .login-tis {
    height: 48px;
    position: absolute;
    top: 50px;
    left: calc(50%);
    transform: translate(-50%, 0);
    background-color: #000;
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 18px;
    font-weight: 500;
    border-radius: 10px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    .tis-img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }

  .img {
    width: 50vw;
    height: 100vh;
    object-fit: cover;
  }

  .input-box {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .inp-top {
      width: 100%;
      display: flex;
      justify-content: end;
      align-items: center;
      margin-top: 50px;

      span {
        font-size: 20px;
        font-weight: 600;
        margin-right: 50px;
        background-image: linear-gradient(to right, #0189be, #32a395);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        height: 30px;
        cursor: pointer;
      }
    }
    .inp-bot {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;

      span {
        font-size: 20px;
        font-weight: 600;
        margin-right: 50px;
        background-image: linear-gradient(to right, #0189be, #32a395);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}
</style>
