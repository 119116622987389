<template>
  <div class="context-box">
    <div class="inp-log">
      <img src="../../assets/imgs/gs-loge.png" alt="" />
      <!-- <span>GreenValley International</span> -->
    </div>
    <div class="inp-title">Sign in to GreenValley</div>
    <div class="inp-context">
      <div :class="emailtType ? 'inp-name  errText' : 'inp-name'">
        Email address
      </div>
      <a-input
        @change="emailRule"
        @blur="inputBlur"
        :class="emailtType ? 'inp-i  errText' : 'inp-i'"
        :style="email ? 'border-color: #32A395;' : ''"
        type="text"
        v-model:value="email"
      ></a-input>

      <div class="inp-name">
        <span :class="passwordType ? 'inp-name  errText' : 'inp-name'"
          >Password</span
        >
        <a @click="onClick">Forgot?</a>
      </div>
      <a-input
        @change="passwordRule"
        @blur="inputBlur"
        :class="passwordType ? 'inp-i  errText' : 'inp-i'"
        type="password"
        v-model:value="password"
      ></a-input>
      <span v-if="passwordType" :class="passwordType ? 'errText text' : 'text'"
        >The password consists of 6-15 characters and must contain both numbers
        and letters</span
      >

      <a-checkbox
        :class="checkedType ? 'inp-checkbox  errText' : 'inp-checkbox'"
        v-model:checked="checked"
        >Click to get exclusive GVI benefits, latest offers, and
        updates.</a-checkbox
      >

      <a-button class="inp-btn" @click="subadd">Sign in</a-button>
      <a class="inp-a" @click="onClick2"> New user? Create your GVl account </a>
      <!-- <a-divider>or</a-divider>
            <a-button class="inp-btn2">
                <img src="../../assets/imgs/gg.png" alt="">
                Sign in with Google
            </a-button> -->
    </div>
  </div>
</template>

<script setup>
import { useCartStore, useAuthStore } from "@/stores";
import Event from "@/stores";
import { useCookies } from "@vueuse/integrations/useCookies";

const authStore = useAuthStore();

const cookies = useCookies(["sails.sid"]);

if (cookies.get("sails.sid")) {
  logInfo("已登录，去首页");
  navigateTo("/");
}

//输入的信息
const checked = ref(false);
const email = ref(""); //stscript@qq.com
const password = ref(""); //123456aB

//父级事件
const emits = defineEmits(["click-forgot", "click-sign", "clickTiss"]);

const subadd = async () => {
  console.log("1231232131231231231", email.value, password.value);
  let type = judge();
  if (type) return;
  if (email.value == "" || password.value == "") {
    emits("clickTiss", "Please fill in the information");
  } else {
    const csrfToken = await authStore.getCsrfToken();
    // const cookies = useCookies(["sails.sid"]);
    let cookie = "";
    let obj = cookies.getAll();
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        cookie += `${key}:${obj[key]}; `;
      }
    }
    console.log(csrfToken, cookie, "csrfToken");
    const myHeaders = new Headers();
    myHeaders.append("Set-Cookie", cookie);
    myHeaders.append("X-CSRF-Token", csrfToken);
    myHeaders.append("Content-Type", "application/json");
    fetch(constants.BASE_URL + api.login + "?sid=" + csrfToken, {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: myHeaders,
      body: JSON.stringify({
        emailAddress: email.value,
        password: password.value,
        source: "web",
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        console.log(data, "data");
        if (data.user) {
          const user = await useFetch(
            constants.BASE_URL + api.getMy + "?sid=" + csrfToken,
            { method: "GET" }
          );
          console.log(user, "user");
          localStorage.setItem("userInfo", JSON.stringify(user.data.value));
          authStore.setUserInfo(user.data.value);
          // const currentTimestamp = Date.now();
          // console.log("当前时间戳：", currentTimestamp);
          // console.log("返回的expireTime(S为单位)：", data.expireTime);
          // const loginReturnTimestamp =
          //   currentTimestamp + Math.floor(data.expireTime) * 1000;
          // console.log("计算后到期的时间戳：", loginReturnTimestamp);
          // const sidExpiryTimestamp = loginReturnTimestamp - 500;
          // const expiryDate = new Date(sidExpiryTimestamp);
          // console.log(expiryDate, "formattedExpiryDate");

          // let expiryDate = data.expireTime / 3600 / 24 - 0.000005;
          // console.log("expiryDate[计算后的过期日期] :>> ", expiryDate);
          // cookies.remove("sails.sid");
          setTimeout(() => {
            cookies.set("sails.sid", csrfToken, {
              path: "/",
              // expires: expiryDate,
              maxAge: data.expireTime - 1,
            });
            console.log("cookies.getAll() :>> ", cookies.getAll());
            // return;
            setTimeout(() => {
              for (const key in cookies.getAll()) {
                if (cookies.getAll().hasOwnProperty(key)) {
                  let options = {};
                  if (key == "sails.sid") {
                    options.path = "/";
                    options.maxAge = data.expireTime - 1;
                  }
                  cookies.set(key, cookies.getAll()[key], options);
                }
                console.log("cookies更新完了");
              }
            }, 1000);
            const route = useRoute();
            console.log(route.query, "route");
            if (route.query.redirect) {
              navigateTo(route.query.redirect);
            } else {
              navigateTo("/");
            }
          }, 200);

          // Event.emit("addCart", "登录页面-加入购物车"); // 暂时放弃这种方式
        } else {
          cookies.remove("sails.sid");
          emits("clickTiss", { msg: data.msg, type: "err" });
        }
      });
  }
};

const onClick = () => {
  emits("click-forgot", "forgetPasswd");
};
const onClick2 = () => {
  emits("click-sign", "forgetPasswd");
};

// 输入框规则
const emailtType = ref(false);
const passwordType = ref(false);
const checkedType = ref(false);
const emailRule = (data) => {
  console.log(email.value, "邮箱");
  const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
  if (reg.test(email.value)) {
    emailtType.value = false;
    return false;
  } else {
    emailtType.value = true;
    return true;
  }
};
const passwordRule = (value) => {
  console.log(passwordType.value, "密码");
  const reg = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&]{6,15}$/;
  if (!reg.test(password.value)) {
    passwordType.value = true;
    return true;
  } else {
    passwordType.value = false;
    return false;
  }
};
const inputBlur = () => {
  if (email.value == "") {
    emailtType.value = false;
  }
  if (password.value == "") {
    passwordType.value = false;
  }
};

//判断是否符合条件
const judge = () => {
  let judges = false;
  if (passwordRule()) {
    passwordType.value = true;
    judges = true;
  }
  // if (checked.value == false) {
  //   checkedType.value = true;
  //   judges = true;
  // }
  if (emailRule()) {
    emailtType.value = true;
    judges = true;
  }
  setTimeout(() => {
    console.log(123123123123213);
    passwordType.value = false;
    emailtType.value = false;
    // checkedType.value = false;
  }, 1000);
  return judges;
};
</script>

<style lang="less" scoped>
.context-box {
  width: 50vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

  .inp-log {
    width: 478px;
    display: flex;
    align-items: center;
    margin-top: 117px;

    img {
      width: 394px;
      height: 33px;
    }
  }

  .inp-title {
    width: 478px;
    font-size: 30px;
    color: #000;
    font-weight: 700;
    text-align: left;
    line-height: 37px;
    margin-top: 63px;
    margin-bottom: 46px;
  }

  .inp-context {
    width: 478px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .errText {
      color: red !important;
      border-color: red !important;
    }

    .text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      text-align: left;
      margin-top: -30px;
      margin-bottom: 30px;
      position: relative;
    }

    .inp-name {
      width: 100%;
      font-size: 20px;
      line-height: 24px;
      color: #000;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;

      a {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .inp-i {
      width: 100%;
      height: 63px;
      border-radius: 10px;
      margin-bottom: 33px;
      font-size: 22px;
    }

    .inp-i-border {
      border-color: #32a395;
    }

    .inp-i:focus {
      border-color: #32a395;
    }

    .inp-checkbox {
      width: 100%;
      text-wrap: nowrap;
      font-size: 14px;
      line-height: 20px;
    }

    .inp-btn {
      width: 100%;
      height: 63px;
      background: linear-gradient(to right, #0189be, #32a395);
      color: #fff;
      font-size: 20px;
      border-radius: 10px;
      margin-top: 22px;
    }

    .inp-btn2 {
      width: 100%;
      height: 63px;
      background: #fff;
      color: #000000;
      font-size: 17px;
      border-radius: 10px;
      font-weight: 700;
      margin-top: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 20px;
        height: 19px;
        margin-right: 20px;
      }
    }

    .inp-a {
      margin: 30px 0 40px 0;
      font-size: 17px;
      line-height: 20px;
      color: #000;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
